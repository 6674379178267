<template>
  <div id="icons-container">
    <div class="d-flex align-center flex-wrap">
      <v-tooltip
        v-for="item in iconsList"
        :key="item.icon"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-card
            v-bind="attrs"
            class="icons-card mb-6 me-6"
            v-on="on"
          >
            <v-card-text class="py-3 px-4">
              <v-icon size="30">
                {{ item.icon }}
              </v-icon>
            </v-card-text>
          </v-card>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </div>

    <!-- more icons -->
    <div class="text-center">
      <v-btn
        href="https://materialdesignicons.com/"
        target="_blank"
        rel="nofollow"
        color="primary"
      >
        View All Material Design Icons
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiAbTesting,
  mdiAbacus,
  mdiAbjadArabic,
  mdiAbjadHebrew,
  mdiAbugidaDevanagari,
  mdiAbugidaThai,
  mdiAccessPoint,
  mdiAccessPointCheck,
  mdiAccessPointMinus,
  mdiAccessPointNetwork,
  mdiAccessPointNetworkOff,
  mdiAccessPointOff,
  mdiAccessPointPlus,
  mdiAccessPointRemove,
  mdiAccountAlertOutline,
  mdiAccountArrowLeftOutline,
  mdiAccountArrowRightOutline,
  mdiAccountBoxMultipleOutline,
  mdiAccountBoxOutline,
  mdiAccountCancelOutline,
  mdiAccountCashOutline,
  mdiAccountCheckOutline,
  mdiAccountChildOutline,
  mdiAccountCircleOutline,
  mdiAccountClockOutline,
  mdiAccountCogOutline,
  mdiAccountDetailsOutline,
  mdiAlarmLightOutline,
  mdiAlertBoxOutline,
  mdiAlertCircleCheckOutline,
  mdiAlertDecagramOutline,
  mdiAlertMinusOutline,
  mdiAlertOutline,
  mdiAlertPlusOutline,
  mdiCheckOutline,
  mdiClipboardOutline,
  mdiClipboardPlayOutline,
  mdiCloseOutline,
  mdiCloudCheckOutline,
  mdiCloudDownloadOutline,
  mdiCogOutline,
  mdiCompassOffOutline,
  mdiContactlessPaymentCircleOutline,
  mdiCrownOutline,
  mdiDeleteOutline,
  mdiDiamondOutline,
  mdiEmailOpenOutline,
  mdiEmoticonHappyOutline,
  mdiFileMultipleOutline,
  mdiFlaskEmptyOutline,
  mdiNewspaperVariantOutline,
  mdiFileVideo,
  mdiBookVariant,
  mdiBookOpenBlankVariant,
  mdiContacts,
  mdiViewDashboard,
  mdiCardBulletedSettings,
  mdiAlphaHCircle,
  mdiNas,
  mdiGitlab,
  mdiPencil,
  mdiTrashCan,
  mdiPlay,
  mdiDesktopClassic,
  mdiDevices,
  mdiIpNetwork,
  mdiLanConnect,
  mdiLanDisconnect,
  mdiConsole,
  mdiPrinterSettings,
  mdiAlphaKBox,
  mdiWeb,
  mdiClipboardTextClockOutline,
  mdiClipboardTextClock,
  mdiUpdate,
  mdiApplicationEditOutline,
  mdiFormatQuoteClose,
  mdiUndo,
  mdiLink,
  mdiFormatUnderline,
  mdiFormatStrikethrough,
  mdiFormatItalic,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiFormatBold,
  mdiCodeTags,
  mdiMinus,
  mdiFormatParagraph,
  mdiExportVariant,
  mdiAlphaFBox,
} from '@mdi/js'

export default {
  setup() {
    // icons list
    const iconsList = [
      { icon: mdiAbTesting, name: 'mdiAbTesting' },
      { icon: mdiAbacus, name: 'mdiAbacus' },
      { icon: mdiAbjadArabic, name: 'mdiAbjadArabic' },
      { icon: mdiAbjadHebrew, name: 'mdiAbjadHebrew' },
      { icon: mdiAbugidaDevanagari, name: 'mdiAbugidaDevanagari' },
      { icon: mdiAbugidaThai, name: 'mdiAbugidaThai' },
      { icon: mdiAccessPoint, name: 'mdiAccessPoint' },
      { icon: mdiAccessPointCheck, name: 'mdiAccessPointCheck' },
      { icon: mdiAccessPointMinus, name: 'mdiAccessPointMinus' },
      { icon: mdiAccessPointNetwork, name: 'mdiAccessPointNetwork' },
      { icon: mdiAccessPointNetworkOff, name: 'mdiAccessPointNetworkOff' },
      { icon: mdiAccessPointOff, name: 'mdiAccessPointOff' },
      { icon: mdiAccessPointPlus, name: 'mdiAccessPointPlus' },
      { icon: mdiAccessPointRemove, name: 'mdiAccessPointRemove' },
      { icon: mdiAccountAlertOutline, name: 'mdiAccountAlertOutline' },
      { icon: mdiAccountArrowLeftOutline, name: 'mdiAccountArrowLeftOutline' },
      { icon: mdiAccountArrowRightOutline, name: 'mdiAccountArrowRightOutline' },
      { icon: mdiAccountBoxMultipleOutline, name: 'mdiAccountBoxMultipleOutline' },
      { icon: mdiAccountBoxOutline, name: 'mdiAccountBoxOutline' },
      { icon: mdiAccountCancelOutline, name: 'mdiAccountCancelOutline' },
      { icon: mdiAccountCashOutline, name: 'mdiAccountCashOutline' },
      { icon: mdiAccountCheckOutline, name: 'mdiAccountCheckOutline' },
      { icon: mdiAccountChildOutline, name: 'mdiAccountChildOutline' },
      { icon: mdiAccountCircleOutline, name: 'mdiAccountCircleOutline' },
      { icon: mdiAccountClockOutline, name: 'mdiAccountClockOutline' },
      { icon: mdiAccountCogOutline, name: 'mdiAccountCogOutline' },
      { icon: mdiAccountDetailsOutline, name: 'mdiAccountDetailsOutline' },
      { icon: mdiAlarmLightOutline, name: 'mdiAlarmLightOutline' },
      { icon: mdiAlertBoxOutline, name: 'mdiAlertBoxOutline' },
      { icon: mdiAlertCircleCheckOutline, name: 'mdiAlertCircleCheckOutline' },
      { icon: mdiAlertDecagramOutline, name: 'mdiAlertDecagramOutline' },
      { icon: mdiAlertMinusOutline, name: 'mdiAlertMinusOutline' },
      { icon: mdiAlertOutline, name: 'mdiAlertOutline' },
      { icon: mdiAlertPlusOutline, name: 'mdiAlertPlusOutline' },
      { icon: mdiCheckOutline, name: 'mdiCheckOutline' },
      { icon: mdiClipboardOutline, name: 'mdiClipboardOutline' },
      { icon: mdiClipboardPlayOutline, name: 'mdiClipboardPlayOutline' },
      { icon: mdiCloseOutline, name: 'mdiCloseOutline' },
      { icon: mdiCloudCheckOutline, name: 'mdiCloudCheckOutline' },
      { icon: mdiCloudDownloadOutline, name: 'mdiCloudDownloadOutline' },
      { icon: mdiCogOutline, name: 'mdiCogOutline' },
      { icon: mdiCompassOffOutline, name: 'mdiCompassOffOutline' },
      { icon: mdiContactlessPaymentCircleOutline, name: 'mdiContactlessPaymentCircleOutline' },
      { icon: mdiCrownOutline, name: 'mdiCrownOutline' },
      { icon: mdiDeleteOutline, name: 'mdiDeleteOutline' },
      { icon: mdiDiamondOutline, name: 'mdiDiamondOutline' },
      { icon: mdiEmailOpenOutline, name: 'mdiEmailOpenOutline' },
      { icon: mdiEmoticonHappyOutline, name: 'mdiEmoticonHappyOutline' },
      { icon: mdiFileMultipleOutline, name: 'mdiFileMultipleOutline' },
      { icon: mdiFlaskEmptyOutline, name: 'mdiFlaskEmptyOutline' },
      { icon: mdiNewspaperVariantOutline, name: 'mdiNewspaperVariantOutline' },
      { icon: mdiFileVideo, name: 'mdiFileVideo' },
      { icon: mdiBookVariant, name: 'mdiBookVariant' },
      { icon: mdiBookOpenBlankVariant, name: 'mdiBookOpenBlankVariant' },
      { icon: mdiContacts, name: 'mdiContacts' },
      { icon: mdiViewDashboard, name: 'mdiViewDashboard' },
      { icon: mdiCardBulletedSettings, name: 'mdiCardBulletedSettings' },
      { icon: mdiAlphaHCircle, name: 'mdiAlphaHCircle' },
      { icon: mdiNas, name: 'mdiNas' },
      { icon: mdiGitlab, name: 'mdiGitlab' },
      { icon: mdiPencil, name: 'mdiPencil' },
      { icon: mdiTrashCan, name: 'mdiTrashCan' },
      { icon: mdiPlay, name: 'mdiPlay' },
      { icon: mdiDesktopClassic, name: 'mdiDesktopClassic' },
      { icon: mdiDevices, name: 'mdiDevices' },
      { icon: mdiIpNetwork, name: 'mdiIpNetwork' },
      { icon: mdiLanConnect, name: 'mdiLanConnect' },
      { icon: mdiLanDisconnect, name: 'mdiLanDisconnect' },
      { icon: mdiConsole, name: 'mdiConsole' },
      { icon: mdiPrinterSettings, name: 'mdiPrinterSettings' },
      { icon: mdiAlphaKBox, name: 'mdiAlphaKBox' },
      { icon: mdiWeb, name: 'mdiWeb' },
      { icon: mdiClipboardTextClockOutline, name: 'mdiClipboardTextClockOutline' },
      { icon: mdiClipboardTextClock, name: 'mdiClipboardTextClock' },
      { icon: mdiUpdate, name: 'mdiUpdate' },
      { icon: mdiApplicationEditOutline, name: 'mdiApplicationEditOutline' },
      { icon: mdiFormatQuoteClose, name: 'mdiFormatQuoteClose' },
      { icon: mdiUndo, name: 'mdiUndo' },
      { icon: mdiLink, name: 'mdiLink' },
      { icon: mdiFormatUnderline, name: 'mdiFormatUnderline' },
      { icon: mdiFormatStrikethrough, name: 'mdiFormatStrikethrough' },
      { icon: mdiFormatItalic, name: 'mdiFormatItalic' },
      { icon: mdiFormatListBulleted, name: 'mdiFormatListBulleted' },
      { icon: mdiFormatListNumbered, name: 'mdiFormatListNumbered' },
      { icon: mdiFormatBold, name: 'mdiFormatBold' },
      { icon: mdiCodeTags, name: 'mdiCodeTags' },
      { icon: mdiMinus, name: 'mdiMinus' },
      { icon: mdiFormatParagraph, name: 'mdiFormatParagraph' },
      { icon: mdiExportVariant, name: 'mdiExportVariant' },
      { icon: mdiAlphaFBox, name: 'mdiAlphaFBox' },
    ]

    return {
      iconsList,
      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>
